//import { HttpClient } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable,inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/auth.models';
//import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

//import { Auth } from '../models/auth.models';
import { map } from 'rxjs/operators';

const baseUrl = 'http://structure.produits-blancs/api/login';
const baseUrlr = 'http://structure.produits-blancs/api/refresh';
const baseUrluser = 'http://structure.produits-blancs/api/user';

@Injectable({
  providedIn: 'root',
})

@Injectable({ providedIn: 'root' })



export class UserService {
  isExpired:boolean=true;
  public $reFreshToken = new Subject<boolean>;

  //http = inject(HttpClient)
  constructor(private http: HttpClient) { 

    this.$reFreshToken.subscribe((res:any) => {
this.getRefreshToken();
    })
  }

  onLogin(user :any): Observable<any> {

    return  this.http.post(baseUrl,user)

  }

  getAll() {
    return this.http.get<User[]>(`/api/login`);
}

register(user: User) {
    return this.http.post(`/users/register`, user);
}

  getRefreshToken(){
debugger;
    let loggUseData : any;
const localData =   localStorage.getItem('Angular17TokenDATA');

if(localData != null){
  loggUseData = JSON.parse(localData);
}

/*
{
    "emailId": "string",
    "token": "string",
    "refreshToken": "string"
}
*/
    const obj ={
      "emailId":loggUseData.EmailId,
      "token":"",
      "refreshToken":loggUseData.refreshToken
    }

      this.http.post(baseUrlr,obj).subscribe((Res:any)=>{
        localStorage.setItem('Angular17TokenDATA',JSON.stringify(Res.data));
      })

  }


  getUser(user :any): Observable<any> {

    return  this.http.get(baseUrluser,user)

  }



  login(user :any) {
    return this.http.post("http://structure.produits-blancs/api/login",user).subscribe();
      
}





/*



  getAll(): Observable<Auth[]> {
    return this.http.get<Auth[]>(baseUrl);
  }

  get(id: any): Observable<Auth> {
    return this.http.get<Auth>(`${baseUrl}/${id}`);
  }*/

  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }

  public isAuthenticated() : boolean {
    const token = localStorage.getItem('authToken');
    //const helper = new JwtHelperService();
    //const isExpired = helper.isTokenExpired(token);
    return this.isExpired;
  }
}
