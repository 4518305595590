<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="20">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="20">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Search...">
          <span class="uil-search"></span>
        </div>
      </form>

    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="uil-search"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" ngbDropdownToggle>

          <span class="ms-1">{{countryName}}</span>
          @if(flagvalue === undefined){
          <img src="{{valueset}}" alt="Header Language" height="16">}
          @else{
          <img src="{{flagvalue}}" alt="Header Language" height="16">}
          @if(flagvalue === undefined){
          <span class="ms-1">English</span>
          }

        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          @for(item of listLang;track $index){
          <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span class="align-middle">{{item.text}}</span>
          </a>}
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
          <i class="bx bx-customize"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github">
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox">
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="uil-minus-path"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" ngbDropdownToggle>
          <i class="uil-bell"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 font-size-16">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}}</h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
            <a href="javascript:void(0);" class="text-dark notification-item">
              <div class="d-flex align-items-start">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="uil-shopping-basket"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="text-dark notification-item">
              <div class="d-flex align-items-start">
                <div class="flex-shrink-0 me-3">
                  <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="text-dark notification-item">
              <div class="d-flex align-items-start">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-success rounded-circle font-size-16">
                      <i class="uil-truck"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="text-dark notification-item">
              <div class="d-flex align-items-start">
                <div class="flex-shrink-0 me-3">
                  <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="uil-arrow-circle-right me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item waves-effect" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-4.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">Marcus</span>
          <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);">
            <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.PROFILE' | translate}}</span>
          </a>
          <a class="dropdown-item" href="javascript: void(0);">
            <i class="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</span>
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);">
            <i class="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.SETTINGS' | translate}}</span>
            <span class="badge badge-soft-success rounded-pill mt-1 ms-2">03</span>
          </a>
          <a class="dropdown-item" href="javascript: void(0);">
            <i class="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</span>
          </a>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
            <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.LOGOUT' | translate}}</span>
          </a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect" (click)="onSettingsButtonClicked()">
          <i class="uil-cog"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="topnav">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <!-- Menu data -->
            @for (item of menuItems; track $index) {
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none side-nav-link-ref" href="javascript: void(0);" id="topnav-components" (click)="onMenuClick($event)" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }}
                @if(hasItems(item)){
                <div class="arrow-down"></div>
                }
              </a>
              @if(hasItems(item)){
              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard">
                @for(subitem of item.subItems;track $index){
                @if(item.subItems.length < 11 && !hasItems(subitem)) { <a class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" href="javascript: void(0);" routerLinkActive="active">{{subitem.label | translate }}
                  </a>}
                  @if(item.subItems.length > 11){
                  <div>
                    @if($index % 3 == 0){
                    <div class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[$index].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[$index + 1]?.link">{{item.subItems[$index + 1]?.label | translate}}</a>
                      </div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[$index + 2]?.link">{{item.subItems[$index + 2]?.label | translate}}</a>
                      </div>
                    </div>}
                  </div>
                  }
                  @if(hasItems(subitem)){
                  <div class="dropdown">
                    <a class="dropdown-item" href="javascript: void(0);" (click)="onMenuClick($event)">{{
                      subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link" routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      </ng-template>
                    </div>
                  </div>}
                  }
              </div>
              }
            </li>
            }
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout" role="button" (click)="onMenuClick($event)">
                <i class="bx bx-layout me-2"></i>{{'MENUITEMS.LAYOUTS.TEXT' | translate}} <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="topnav-layout">
                <a href="javascript: void(0);" class="dropdown-item" (click)="changeLayout('vertical')">{{
                  'MENUITEMS.LAYOUTS.LIST.VERTICAL' | translate}}</a>
                <a href="javascript: void(0);" class="dropdown-item" (click)="topbarDark()">{{
                  'MENUITEMS.LAYOUTS.LIST.LIGHTTOPBAR' | translate}}</a>
                <a href="javascript: void(0);" class="dropdown-item" (click)="boxedWidth()">{{
                  'MENUITEMS.LAYOUTS.LIST.BOXED' | translate}}</a>

              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>