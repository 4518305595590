import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading = new BehaviorSubject(false);
  public readonly loading$=this.isLoading.asObservable();
  constructor(private spinner: NgxSpinnerService) {  }

  montre(){
      this.isLoading.next(true);
      this.spinner.show();
      setTimeout(()=> {
        this.spinner.hide();

      },5000)
  }


  cacher(){
    this.isLoading.next(false);
}



}
