export const environment = {
  production: false,
  defaultauth: 'fackbackend',
    Config: {
    apiKey: '',
    authDomain: '',
    databaseURL: 'https://caistabapp.alwaysdata.net/api/',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};


