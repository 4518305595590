import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { LoginComponent } from './account/auth/login/login.component'; 
//import { DefaultComponent } from './pages/dashboards/default/default.component';
const routes: Routes = [
 /*{ path: '', redirectTo:'auth'  , pathMatch:'full'},
 { path: 'auth', component: LoginComponent },
 { path: '', 
  children:[
    { path: 'dashboards', redirectTo:''  },
    { path: '', component: LayoutComponent,  loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
   
  ]
  
 },*/
 { path: 'auth', component: LoginComponent },
 
 { path: '', component: LayoutComponent,  loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
 { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
 { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
