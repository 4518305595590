import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';


import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/core/services/user.service';
@Injectable({ providedIn: 'root' })


/*export class AuthGuard  {
    constructor(
        private router: Router,
      
        private authenticationService: AuthenticationService,
        private UserService: UserService,
        private authFackservice: AuthfakeauthenticationService
    ) { }



    canActivate(): boolean {
        if (this.UserService.isAuth.value == false) { 
          this.router.navigateByUrl('auth');
         // this.router.navigate(['/auth']);
         return false;
        } else {
            this.router.navigate(['/account/login']);
          return false;
        }

            return this.UserService.isAuth.value;
}

}*/


export class AuthGuard  {
  
    constructor(
        private router: Router,
        private UserService: UserService,
        private authenticationService: AuthenticationService,
        private authFackservice: AuthfakeauthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let loggeruserData={};
        const localData=localStorage.getItem('Angular17TokenDATA')
        if(localData!=null){
            loggeruserData=JSON.parse(localData);
            return true;
        }else{
            this.router.navigateByUrl('auth');
            return false;
        }



    /* if(this.UserService.isAuth.value == true){
      return true;
     }else{
      this.router.navigateByUrl('auth');
      return false;
     }*/
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}



