import { MenuItem } from './menu.model';

export const MENUADMIN: MenuItem[] = [
    
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        badge: {
            variant: 'primary',
            text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/',
    },

    {
        id: 3,
        label: 'Affectation',
        icon: 'uil-store',
        subItems: [
            {
                id: 4,
                label: 'Equipement',
                link: '/equipements/informatique',
                parentId: 5
            },
            {
                id: 6,
                label: 'Site',
                link: '/equipements/site',
                parentId: 7
            },
            {
                id: 6,
                label: 'Affecter',
                link: '/equipements/affecter',
                parentId: 7
            },
            {
                id: 6,
                label: 'Bénéficiaire',
                link: '/equipements/beneficiaire',
                parentId: 7
            },
          
        ]
    },

    {
        id: 8,
        label: 'Paramètres',
        icon: 'uil-invoice',
        subItems: [
            {
                id: 9,
                label: 'Utilisateur',
                link: '/parametres/utilisateur',
                parentId: 8
            },
            {
                id: 9,
                label: 'Général',
                link: '/parametres/basics',
                parentId: 8
            }
         
        ]
    },
    {
        id: 10,
        label: 'Gestion',
        icon: 'uil-invoice',
        subItems: [
            {
                id: 11,
                label: 'Parc',
                link: '/gestion/parc',
                parentId: 10
            },
            {
                id: 11,
                label: 'Liste',
                link: '/gestion/liste',
                parentId: 10
            }
         
        ]
    },
    {
        id: 12,
        label: 'Listes',
        icon: 'uil-book-alt',
        subItems: [
            {
                id: 12,
                label: 'Directions',
                link: '/bordereau/saisie',
                parentId: 13
            },
            {
                id: 12,
                label: 'Sites',
                link: '/bordereau/enregistrement',
                parentId: 13
            }
        ]
    },
  

];

