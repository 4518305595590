import { Component, OnInit, inject , Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormGroup, Validators } from '@angular/forms';
import {Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {
  @Output()  email='';
  loginForm: UntypedFormGroup;
  submitted = false;
  error = '';
  returnUrl: string;
 
  http = inject(HttpClient);

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private spinner: NgxSpinnerService,private usersrc: UserService) { }

  ngOnInit() {

     /** spinner starts on init */

     
     this.spinner.show();

     setTimeout(() => {
       /** spinner ends after 5 seconds */
       this.spinner.hide();
     }, 5000);
   

    document.body.setAttribute('class', 'authentication-bg');

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

  }

  opensource(){
    this.spinner.show();

     setTimeout(() => {
       /** spinner ends after 5 seconds */
       this.spinner.hide();
     }, 5000);

  }

  ngAfterViewInit() {
  }

  ngOnDestroy() { 
    document.body.classList.remove('authentication-bg')
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */

/*
  const data = {
    title: this.tutorial.title,
    description: this.tutorial.description
  };*/

 
/*
{
    "emailId": "string",
    "token": "string",
    "refreshToken": "string"
}
*/

  onSubmit() {
 
    this.submitted = true;
    // stop here if form is invalid

    
    if (this.loginForm.invalid) {
      return;
    } else {
      
      this.usersrc.onLogin(this.loginForm.value).subscribe({
        next: (res) => {
          if(res.result){
           // console.log(this.loginForm.value)
          //  console.log(res)

//this.email=res.data.email;

            
          
            localStorage.setItem('Angular17TokenDATA',JSON.stringify(res.data));
            localStorage.setItem('Angular17TokenEmail',res.data.email); 
            localStorage.setItem('Angular17TokenIduser',JSON.stringify(res.data.iduser));
            localStorage.setItem('RoleUtil',JSON.stringify(res.data.role));
            this.usersrc.roles.next( localStorage.getItem('RoleUtil'));
            document.body.removeAttribute('class');
            this.router.navigate(['/']);
           // console.log(JSON.parse(localStorage.getItem('Angular17TokenDATA')))
           // this.router.navigate(['pages']);
           
          }
        
         
        },
        error: (e) => console.error(e)
      });

    }

  
  }
}
