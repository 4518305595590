import { HttpClient } from '@angular/common/http';
import { Injectable,inject } from '@angular/core';
import { Observable, BehaviorSubject ,Subject } from 'rxjs';


import { getFirebaseBackend } from '../../authUtils';
import { environment } from 'src/environments/environment';

//import { User } from '../models/auth.models';

import { User } from '../bd/models/user.models';

import { map } from 'rxjs/operators';
/*
export interface Todo {
  id?: any;
  createdAt?: number;
  value: string;
}*/

const baseUrl = environment.Config.databaseURL;
const baseUrlr = 'http://structure.produits-blancs/api/refresh';
const baseUrluser = 'http://structure.produits-blancs/api/user';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    isExpired:boolean=true;
    public $reFreshToken = new Subject<boolean>;

    private _todos = new BehaviorSubject<User[]>([]);
    private dataStore: { todos: User[] } = { todos: [] };
    readonly todos = this._todos.asObservable();


    user: User;

    constructor(private http: HttpClient) { 
        this.$reFreshToken.subscribe((res:any) => {
            this.getRefreshToken();
                })
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */



    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }



    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }


     //   EnregistrerLocalite
  create(data:User): Observable<any> {
    return this.http.post(baseUrl+`Enregistrer`, data);
  }


  getRefreshToken(){
    debugger;
        let loggUseData : any;
    const localData =   localStorage.getItem('Angular17TokenDATA');
    
    if(localData != null){
      loggUseData = JSON.parse(localData);
    }
    
    
        const obj ={
          "emailId":loggUseData.EmailId,
          "token":"",
          "refreshToken":loggUseData.refreshToken
        }
    
          this.http.post(baseUrlr,obj).subscribe((Res:any)=>{
            localStorage.setItem('Angular17TokenDATA',JSON.stringify(Res.data));
          })
    
      }
}

