import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        badge: {
            variant: 'primary',
            text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/',
    },

  

    {
        id: 3,
        label: 'Listes',
        icon: 'uil-book-alt',
        subItems: [
            {
                id: 3,
                label: 'Directions',
                link: '/bordereau/saisie',
                parentId: 4
            },
            {
                id: 3,
                label: 'Sites',
                link: '/bordereau/enregistrement',
                parentId: 5
            }
        ]
    },
  

];

