<div class="parents">
  <div class="login-img3-body">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form" >
                  <div class="login-wrap">
                      <p class="login-img"><i class="icon_lock_alt"></i></p>
                      <div class="input-group">
                          <span class="input-group-addon"><i class="icon_profile"></i></span>
                          <input type="email" formControlName="email" class="form-control" id="email" 
                          placeholder="Email"  autofocus/>
                      </div>
                      <div class="input-group">
                          <span class="input-group-addon"><i class="icon_key_alt"></i></span>
                          <input type="password" formControlName="password" class="form-control" id="password"
                           placeholder="Password" />
                      </div>
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="auth-remember-check">
                        <label class="form-check-label" for="auth-remember-check">Se souvenir de moi</label>
                      </div>
                     
                      <div class="mt-1 text-center">
                        <button class="btn btn-primary w-sm waves-effect waves-light" type="submit">Connection</button>
                      </div>
  
                      <div class="mb-3">
                        <div class="float-end">
                          <a href="/account/reset-password" class="text-muted">Mot de passe oublié ?</a>
                        </div>
                      
                      </div>
                  </div>
                </form>
          <div class="mt-5 text-center">
            <p>©  Réalisation <i class="mdi mdi-heart text-danger"></i> Caistabgabon</p>
          </div>
  
        </div>
      </div>
  
  
    <!-- end container -->
  </div>
  
  </div>
  <!-- end page -->