import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/core/services/user.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private UserService: UserService,
        private authFackservice: AuthfakeauthenticationService
    ) { }



    canActivate(): boolean {
        if (this.UserService.isAuthenticated()) {
          return true;
        } else {
            this.router.navigate(['/account/login']);
          return false;
        }
}

}
