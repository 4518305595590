import { Component,Input,  Injectable, PipeTransform,inject, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';



import { BehaviorSubject, Observable, of,Subject} from 'rxjs';
//import { User } from '../models/auth.models';
// import { Utilisateur } from './utilisateur';
import { Utilisateur } from 'src/app/pages/parametre/utilisateur/utilisateur'; 
import { Router } from '@angular/router';


//import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
//import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

//import { Auth } from '../models/auth.models';


import { environment } from 'src/environments/environment';

import { map } from 'rxjs/operators';

import { User } from '../bd/models/user.models';


import { getFirebaseBackend } from '../../authUtils';


//import { User } from '../models/auth.models';

//import { User } from '../bd/models/user.models';



import { Product } from 'src/app/pages/parametre/utilisateur/product';





import { IUtilisateur } from '../bd/interfeces/utilisateur.model';
//import { listData } from 'src/appsup/pages/parametre/basic/data';
//import { InvoiceList } from './list.model';
//import { listData } from './data';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';


import { SortColumn,SortDirection } from 'src/app/pages/parametre/utilisateur/sortable.directive';
//import console = require('console');
//import { Observable } from 'rxjs/dist/types/internal/Observable';

const baseUrl = environment.Config.databaseURL;
//const baseUrlr = 'http://structure.produits-blancs/api/refresh';
//const baseUrluser = 'http://structure.produits-blancs/api/user';

interface Produit {
  index: number;
  nom: string;
  id: string;
  prenom: string;
  email: string;
  password: string;
}

interface Utilis {
  index: number;
  nom: string;
  id: string;
  prenom: string;
  email: string;
  password: string;
}


interface Transaction {
  index: number;
  nom: string;
  id: string;
  prenom: string;
  email: string;
  password: string;
}




let feriesList: IUtilisateur[] = [];

let feriesList2: IUtilisateur[] = [];


interface SearchResult {
  test: IUtilisateur[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(
  test: IUtilisateur[],
  column: SortColumn,
  direction: string
): IUtilisateur[] {
  if (direction === '' || column === '') {
    return test;
  } else {
    return [...test].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(invoice: IUtilisateur, term: string, pipe: PipeTransform) {
  return invoice.id.toLowerCase().includes(term) ||
   // invoice.index.toLowerCase().includes(term) ||
   invoice.email.toLowerCase().includes(term.toLowerCase()) ||
   invoice.password.toLowerCase().includes(term.toLowerCase()) ||
    invoice.nom.toLowerCase().includes(term.toLowerCase()) ||
    invoice.prenom.toLowerCase().includes(term.toLowerCase())
    ;
}



@Injectable({ providedIn: 'root' })




export class UserService  {

  public isAuth = new BehaviorSubject<boolean>(false);

  public  monutil: IUtilisateur[] = [];
  public  roles= new BehaviorSubject<string>('');
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _invoices$ = new BehaviorSubject<IUtilisateur[]>([]);
  private _test$ = new BehaviorSubject<IUtilisateur[]>([]);
  private _listeutilisateurs$ = new BehaviorSubject<IUtilisateur[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
   public listDatas:any= [{"index":1,"id":"66ba06ec4abd1b112c00e476","nom":"zaaza","prenom":"dsdsd","password":"10 Jul, 2020","email":"max@gmail.com"}];

   public monutil1=[{"index":1,"id":"66ba06ec4abd1b112c00e476","nom":"zaazamol","prenom":"dsdsd","password":"10 Jul, 2020","email":"max@gmail.com"},
    {"index":2,"id":"66ba07034abd1b112c00e478","nom":"zaaza1","prenom":"dsdsd1pal","password":"10 Jul, 2020","email":"maxe@gmail.com"}];

    public monutil2:any;

   public  _todos = new BehaviorSubject<Utilis[]>([]);
   public dataStore: { todos: Utilis[] } = { todos: [] };
   public readonly todos = this._todos.asObservable();

  //listusers: any[] = [];
   data: any;
   public userdata:IUtilisateur[]=[];
 
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

   List: Produit[] = [];
   public   lalisteutil: IUtilisateur[] = [];
    //http = inject(HttpClient)
    constructor(private pipe: DecimalPipe,public http: HttpClient,private router: Router) {
     // this.listeheroes();

     // this.monutil= [{"index":1,"id":"66ba06ec4abd1b112c00e476","nom":"zaaza","prenom":"dsdsd","password":"10 Jul, 2020","email":"max@gmail.com"}];

      this.http.get<IUtilisateur[]>(baseUrl+`ListeUtilisateur`).subscribe(
        data => {
          this.dataStore.todos=data;
        return  this.monutil=data;
          this._todos.next(Object.assign({}, this.dataStore).todos);
        },
        error => console.log('Could not load todos.')
      );
      
     this.getListeUtilisateurs();
//this.getAlluser4();
//this.chargedata(JSON.stringify(this.userdata));

    /*  this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._invoices$.next(result.invoices);
        this._total$.next(result.total);
      });*/

      this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._test$.next(result.test);
        this._total$.next(result.total);
      });

    this._search$.next();

    this._search$.next();

      this.$reFreshToken.subscribe((res:any) => {
  this.getRefreshToken();
      })
     //this.listDatas=[];

     


     


      //  const feriesList: IUtilisateur[] = [];
  /* this.http.get<IUtilisateur[]>(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
   // this.listDatas=[];
   const feriesList: IUtilisateur[] = [];
    for (let ferier of res) {
      feriesList.push({ index: ferier.index, nom: ferier.nom, id: ferier.nom, prenom: ferier.nom, email: ferier.nom, password: ferier.nom});
      }*/

     
     // this.listDatas=JSON.stringify(feriesList);
       //this.lislistDatas=JSON.stringify(feriesList)
//;
// this.userData=JSON.stringify(feriesList);
 //console.log(feriesList);
//alert(  this.listDatas);
//console.log(  this.listDatas);
// this._utilisateurs.next(this.utilisateurList);
// alert(this.heroes[0]);
// console.log(feriesList);
// return JSON.stringify(feriesList);
//});


      //this.listDatas=this.getUtilisateur();
     // alert( this.getUtilisateur(this.listDatas));
    // alert(this.listDatas);
     //this.listDatas=this.listDatas;
    
   // })
   // this.listDatas=feriesList;
 // this. getAlluser(this.listDatas);

   //alert(this.getAlluser(this.listDatas));
  // this.getAlluser(this.listDatas);

  /*this.http.get(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
    this.data=res;
    for (let i=0; i< this.data.length; i++) 
      {
        this.userdata.push(this.data[i]);
    }*/
   // console.log(JSON.stringify(this.userdata));

   // this.chargedata(JSON.stringify(this.userdata));

   // this.listusers=JSON.stringify(this.listuser);
    //this.listusers=JSON.stringify(this.listuser)
   // console.log('this.listuser');
 
  // console.log(JSON.stringify(userdata));
  
 // this.chargedata(user);
  //})

 
  }
/*
  get todos() {
    return this._todos.asObservable();
  }*/

  loadAll() {
    this.http.get<IUtilisateur[]>(baseUrl+`ListeUtilisateur`).subscribe(
      data => {
        this.dataStore.todos=data;
        this._todos.next(Object.assign({}, this.dataStore).todos);
      },
      error => console.log('Could not load todos.')
    );
  }

  /*

  load(id: number | string) {
    this.http.get<Todo>(`${this.baseUrl}/todos/${id}`).subscribe(
      data => {
        let notFound = true;

        this.dataStore.todos.forEach((item, index) => {
          if (item.id === data.id) {
            this.dataStore.todos[index] = data;
            notFound = false;
          }
        });

        if (notFound) {
          this.dataStore.todos.push(data);
        }

        this._todos.next(Object.assign({}, this.dataStore).todos);
      },
      error => console.log('Could not load todo.')
    );
  }

  create(todo: Todo) {
    this.http
      .post<Todo>(`${this.baseUrl}/todos`, JSON.stringify(todo))
      .subscribe(
        data => {
          this.dataStore.todos.push(data);
          this._todos.next(Object.assign({}, this.dataStore).todos);
        },
        error => console.log('Could not create todo.')
      );
  }

  update(todo: Todo) {
    this.http
      .put<Todo>(`${this.baseUrl}/todos/${todo.id}`, JSON.stringify(todo))
      .subscribe(
        data => {
          this.dataStore.todos.forEach((t, i) => {
            if (t.id === data.id) {
              this.dataStore.todos[i] = data;
            }
          });

          this._todos.next(Object.assign({}, this.dataStore).todos);
        },
        error => console.log('Could not update todo.')
      );
  }

  remove(todoId: number) {
    this.http.delete(`${this.baseUrl}/todos/${todoId}`).subscribe(
      response => {
        this.dataStore.todos.forEach((t, i) => {
          if (t.id === todoId) {
            this.dataStore.todos.splice(i, 1);
          }
        });

        this._todos.next(Object.assign({}, this.dataStore).todos);
      },
      error => console.log('Could not delete todo.')
    );
  }

  */

 
  chargedata(liste:any){
    this.userdata=liste;
  }


  getAlluser4():any {
     this.http.get<IUtilisateur[]>(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
      this.data=res;
      for (let i=0; i< this.data.length; i++) 
        {
          this.userdata.push(this.data[i]);
      }

     
     
      this._listeutilisateurs$.next(this.userdata);
     
     // console.log(JSON.stringify(this.userdata));
     return this._listeutilisateurs$;
    
    })
    //return JSON.stringify(this.userdata);
   
  }
  
  
  getAlluser40() {
    this.http.get(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
     this.data=res;
     for (let i=0; i< this.data.length; i++) 
       {
         this.userdata.push(this.data[i]);
     }
   //  this.Futilisateur(this.userdata);
     this.lalisteutil=this.userdata;
   
   })
  

 } 

 getAlluser41():Observable<any> {
  return this.http.get(baseUrl+`ListeUtilisateur`);
} 
  gettransaction() {
   return this.http.get<Transaction[]>(baseUrl+`ListeUtilisateur`);
 } 


 gettransaction4():Observable<any> {
  return this.http.get<Transaction[]>(baseUrl+`ListeUtilisateur`);
} 
   

  get listeutilisateurs$() {
    return this._listeutilisateurs$.asObservable();
  }

  get invoices$() {
    return this._invoices$.asObservable();
  }

  get invoices2$() {
    return this._invoices$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  
/*
  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
    } = this._state;

    

    //this.listDatas=this.getUtilisateur();
    //alert( this.listDatas);
    // 1. sort
    //let invoices = sort(listData, sortColumn, sortDirection);

    let invoices = sort(this.userdata, sortColumn, sortDirection);

    // 2. filter
    invoices = invoices.filter((customer) =>
      matches(customer, searchTerm, this.pipe)
    );
    const total = invoices.length;

    // 3. paginate
    invoices = invoices.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ invoices, total });
  }*/

  Utilisateurdatas: Utilisateur[] = [];
  Utilisateurdatas2: any[] = [];


  isExpired:boolean=true;
  public $reFreshToken = new Subject<boolean>;


private _userdata: BehaviorSubject<Produit[]>; 
//public userdata: Produit[] = [];



/*
  getallUtilisateur(): Observable<Utilisateur[]> {
    const utilisateurdata = of(this.http.get(baseUrl+`ListeUtilisateur`));
   // return utilisateurdata;
  }*/

  onLogin(user :any): Observable<any> {
    this.isAuth.next(true);
    return  this.http.post(baseUrl+`login`,user)

  }

  logout() {
    this.isAuth.next(false);
    this.router.navigate(['/auth']);
   // return  this.http.post(baseUrl+`login`,user)

  }

  user() {
    return  this.http.get(baseUrl+`me`)
  }

  getAll() {
    return this.http.get<User[]>(`/api/login`);
}

getAlls() :Observable<IUtilisateur>{
 return this.http.get<IUtilisateur>(baseUrl+`ListeUtilisateur`);
}


getAlls2() :any{
//  return this.http.get(baseUrl+`ListeUtilisateur`);
 }

/*
 listeheroes():any{

     this.http.get(baseUrl+`ListeUtilisateur`).subscribe((data:any) => {
      let feriesList: IUtilisateur[] = [];

      for (let IUtilisateur of data) {
        feriesList.push(IUtilisateur);
      //  this.monutil2.push(IUtilisateur);
       // this._utilisateurs.next(this.utilisateurList);
      //  this.addNewProduct(ferier);
       
      }

      this.monutil2=feriesList;

     
      return this.monutil2

     });
  
}*/

/*
getUtilisateurs():Observable<any>  {
  return  this.liste(); 
}*/


getUtilisateurssup(): Observable<Utilisateur[]> {
  //return this.http.get<Utilisateur[]>(this.heroesUrl)
  return this.http.get<Utilisateur[]>(baseUrl+`ListeUtilisateur`);
}

register(user: User) {
    return this.http.post(`/users/register`, user);
}




  getRefreshToken(){
debugger;
    let loggUseData : any;
const localData =   localStorage.getItem('Angular17TokenDATA');

if(localData != null){
  loggUseData = JSON.parse(localData);
}

/*
{
    "emailId": "string",
    "token": "string",
    "refreshToken": "string"
}
*/
    const obj ={
      "emailId":loggUseData.EmailId,
      "token":"",
      "refreshToken":loggUseData.refreshToken
    }

      this.http.post(baseUrl,obj).subscribe((Res:any)=>{
        localStorage.setItem('Angular17TokenDATA',JSON.stringify(Res.data));
      })

  }


  


  getUser(user :any): Observable<any> {

    return  this.http.get(baseUrl,user)

  }



  login(user :any) {
    return this.http.post(baseUrl+`login`,user).subscribe();
      
}




get monuserdata$() {
  return this._userdata.asObservable();
}

/*
  getAll(): Observable<Auth[]> {
    return this.http.get<Auth[]>(baseUrl);
  }

  get(id: any): Observable<Auth> {
    return this.http.get<Auth>(`${baseUrl}/${id}`);
  }*/

  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }

/*
  listes(): Observable<any> {
    return this.http.get(baseUrl+`ListeUtilisateur`);
  }*/

  listes(): Observable<Utilisateur[]> {
    //return this.http.get<Utilisateur[]>(this.heroesUrl)
    return this.http.get<Utilisateur[]>(baseUrl+`ListeUtilisateur`);
  }


  maliste(): any {
    //return this.http.get<Utilisateur[]>(this.heroesUrl)
    return this.http.get(baseUrl+`ListeUtilisateur`);
  }

  malisteu():  Observable<any> {
    //return this.http.get<Utilisateur[]>(this.heroesUrl)
    return this.http.get(baseUrl+`ListeUtilisateur`);
  }

  getUtilisateur(table:any): any{
  table="test";
  //alert(table);
    //return this.http.get<Utilisateur[]>(this.heroesUrl)
    
  /*  const feriesList: IUtilisateur[] = [];
   this.http.get<IUtilisateur[]>(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
      for (let ferier of res) {
        feriesList.push({ index: ferier.index, nom: ferier.nom, id: ferier.nom, prenom: ferier.nom, email: ferier.nom, password: ferier.nom});
      }*/
     // this.listDatas=JSON.stringify(feriesList);
       //this.lislistDatas=JSON.stringify(feriesList)
;
     // this.userData=JSON.stringify(feriesList);
    // console.log(feriesList);
     //console.log( userdats);
     // this._utilisateurs.next(this.utilisateurList);
     // alert(this.heroes[0]);
    // console.log(feriesList);
    // return JSON.stringify(feriesList);
    //});

   
    //return feriesList;
  }
/*
  liste(): Observable<any> {
    return this.http.get(baseUrl+`ListeUtilisateur`);
  }*/

    


  liste() {
    //return this.http.get<Utilisateur[]>(this.heroesUrl)

   // console.log( this.http.get(baseUrl+`ListeUtilisateur`));
    return this.http.get(baseUrl+`ListeUtilisateur`);
  }


  listeheros() {
    return  this.http.get(baseUrl+`ListeUtilisateur`);
  }

  public isAuthenticated() : boolean {
    const token = localStorage.getItem('authToken');
    //const helper = new JwtHelperService();
    //const isExpired = helper.isTokenExpired(token);
    return this.isExpired;
  }


  getUtilisateurs(){
  
    //return this.http.get<Utilisateur[]>(this.heroesUrl)
  
   this.http.get<Produit[]>(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
     
      for (let ferier of res) {
        this.userdata.push({ index: ferier.index, nom: ferier.nom, id: ferier.nom, prenom: ferier.nom, email: ferier.nom, password: ferier.nom});
       
      }

      this._userdata.next(this.userdata);
     console.log(this._userdata);
    // console.log( JSON.stringify(feriesList));
     // alert(this.heroes[0]);
    // console.log(feriesList);
    // return JSON.stringify(feriesList);
    });

   
    //return feriesList;
  }


  getListeUtilisateurs(){
  
    //return this.http.get<Utilisateur[]>(this.heroesUrl)
  
  // this.http.get(baseUrl+`ListeUtilisateur`).subscribe((res: any) => {
   // const feriesList: IUtilisateur[] = [];
     
     // for (let ferier of res) {
     //   feriesList.push({ index: ferier.index, nom: ferier.nom, id: ferier.nom, prenom: ferier.nom, email: ferier.nom, password: ferier.nom});
       
    //  }
     // this._listeutilisateurs$.next(feriesList);

     // this._userdata.next(this.userdata);
    // console.log(this._listeutilisateurs$);
    // console.log( JSON.stringify(feriesList));
     // alert(this.heroes[0]);
    // console.log(feriesList);
    // return JSON.stringify(feriesList);
   // });

   
    //return feriesList;
  }


  /*  ****************************** Test *************************************  */


  getAllProducts(): Observable<Product> {
    return this.http.get<Product>(baseUrl+`ListeUtilisateur`);
  }


  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
    } = this._state;

   

    // 1. sort
    let test = sort(this.listDatas, sortColumn, sortDirection);

    // 2. filter
    test = test.filter((customer) =>
      matches(customer, searchTerm, this.pipe)
    );
    const total = test.length;

    // 3. paginate
    test = test.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ test, total });
  }


  unUtilisateur(id: any): Observable<any> {
    return this.http.get(baseUrl+`unUtilisateur/`+ id);
  }


  modifier(id: any,data:User): Observable<any> {
    return this.http.put(baseUrl+`ModifierUtilisateur/`+ id,data);
  }


  supprimer(id: any): Observable<any> {
    return this.http.delete(baseUrl+`SupprimerUtilisateur/`+ id);
  }

  enregistrer(data:User): Observable<any> {
    return this.http.post(baseUrl+`Enregistrer/`, data);
  }

}
